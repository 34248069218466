// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booklets-js": () => import("./../../../src/pages/booklets.js" /* webpackChunkName: "component---src-pages-booklets-js" */),
  "component---src-pages-translations-js": () => import("./../../../src/pages/translations.js" /* webpackChunkName: "component---src-pages-translations-js" */),
  "component---src-templates-booklet-js": () => import("./../../../src/templates/booklet.js" /* webpackChunkName: "component---src-templates-booklet-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-topic-js": () => import("./../../../src/templates/topic.js" /* webpackChunkName: "component---src-templates-topic-js" */),
  "component---src-templates-translation-js": () => import("./../../../src/templates/translation.js" /* webpackChunkName: "component---src-templates-translation-js" */)
}

